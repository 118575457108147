let MODE = 'product';
export const CURRENT_HOST =
    MODE === "dev"
        ? "http://localhost:80"
        : "http://35.183.16.171";

export const isMobile = window.screen.width < 768;
// export const WEBSOCKET_URL = "ws://localhost:5000";
export const WEBSOCKET_URL = "ws://35.183.16.171:80";

export const IntervalRefreshSupport = 30; // 10s

export const MAIN_PM2_PROCESSES = [
    'main',
    'rank1',
    'rank2'
];

export const OTHER_PM2_PROCESSES = [
    'scraping',
    'meteora-rank',
    'moonshot'
]