import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WithAuth } from "../../components/with-auth";
import ChatInterface from '../../components/chat-interfact';
import MainWrapper from "../../layout/MainWrapper";
import { createAxiosInstance } from "../../api/ApiRequest";
import type { GetProp, TableProps, InputRef, TableColumnType } from 'antd';
import { Button, Input, Space, Table, Modal } from 'antd';
import type { FilterDropdownProps, SorterResult } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CheckCircleTwoTone, EditOutlined, MessageTwoTone } from "@ant-design/icons";
import { format } from 'date-fns';
import { notifyError } from "../../utils/toast";
import { IntervalRefreshSupport } from "../../Constants";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
    _id: String,
    index: Number,
    fullname: String,
    username: String,
    chatid: Number,
    lastcontent: String,
    lastasktime: Date | String,
    answered: Boolean,
    receiverTgId: String,
    receiverTgUsername: String,
    lastanswerTime: Date | String
}
type DataIndex = keyof DataType;

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

interface Message {
    id: number;
    text: string;
    sender: "user" | "admin";
    timestamp: number;
}

const getParams = (params: TableParams) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
});

const AnswerQuestion: React.FC = () => {
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
    
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [totalUsers, setTotalUsers] = useState(0);
    const [editId, setEditId] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [userMessages, setUserMessages] = useState<Message[]>([]);
    const [data, setData] = useState<DataType[]>();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const searchInput = useRef<InputRef>(null);

    setTimeout(() => {
        setRefresh(!refresh);
    }, IntervalRefreshSupport * 1000);

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'index',
            sorter: false,
            width: '5%'
        },
        {
            title: 'User',
            children: [
                {
                    title: 'User Name',
                    dataIndex: 'fullname',
                    sorter: false,
                    ...getColumnSearchProps('fullname'),
                    width: '15%',
                },
                {
                    title: 'Telegram Username',
                    dataIndex: 'username',
                    width: '10%',
                    sorter: false,
                    ...getColumnSearchProps('username'),
                },
                {
                    title: 'Telegram Id',
                    dataIndex: 'chatid',
                    width: '7%',
                    sorter: false,
                },
            ]
        },
        {
            title: 'Last Content',
            dataIndex: 'lastcontent',
            width: '8%',
        },
        {
            title: 'Last Ask Time',
            dataIndex: 'lastasktime',
            width: '10%',
            sorter: false,
            render: (item) => format(new Date(item), "yyyy-MM-dd HH:mm")
        },
        {
            title: 'Answered?',
            dataIndex: 'answered',
            width: '5%',
            sorter: false,
            render: (item) => {
                if (item) return <CheckCircleTwoTone />;
                else return <MessageTwoTone twoToneColor="#f54444" />
            },
        },
        {
            title: 'Admin',
            children: [
                {
                    title: 'Telegram Username',
                    dataIndex: 'receiverTgUsername',
                    width: '10%',
                    sorter: false,
                    ...getColumnSearchProps('receiverTgUsername'),
                },
                {
                    title: 'Telegram Id',
                    dataIndex: 'receiverTgId',
                    width: '10%',
                    sorter: false,
                    ...getColumnSearchProps('receiverTgId'),
                },
            ]
        },
        {
            title: 'Last Answer Time',
            dataIndex: 'lastanswerTime',
            width: '10%',
            sorter: false,
            render: (item) => item.length > 0 ? format(new Date(item), "yyyy-MM-dd HH:mm") : ""
        },
        {
            title: 'Action',
            dataIndex: 'chatid',
            width: '5%',
            sorter: false,
            render: (item) => (
                <>
                    <a href="#" onClick={() => getMessages(item)}><EditOutlined /></a>
                </>
            )
        }
    ];

    const getMessages = async (id: number) => {
		setEditId(id);
        setLoading(true);
        let results = await axiosInstance.post('/api/v1/get_user_faq', { chatid: id });
        if (results.data && results.data.flag && results.data.flag == true) {
            setUserMessages(results.data.messages);
            setLoading(false);
            setModalOpen(true);
        } else {
            setLoading(false);
            notifyError(results.data.message);
        }
    }

    const fetchData = async () => {
        setLoading(true);
        let results = await axiosInstance.post('/api/v1/get_questions', getParams(tableParams))
        if (results.data && results.data.flag && results.data.flag == true) {
            setData(results.data.questions);
            setTotalUsers(results.data.totalNum);
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: results.data.totalNum,
                },
            });
        } else {
			notifyError(results.data.message);
			localStorage.removeItem('token');
			history('/login');
        }
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
        modalOpen,
        refresh
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    return (
        <WithAuth>
            <MainWrapper>
                <span>Total: {totalUsers}</span>
                <Table
                    columns={columns}
                    rowKey={(record) => record._id.toString()}
                    dataSource={data}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </MainWrapper>
            <Modal
                title="Chat History"
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                width={1500}
            >
                <ChatInterface key={JSON.stringify(userMessages)} newMessages={userMessages} userChatId={editId} />
            </Modal>
        </WithAuth>
    );
};

export default AnswerQuestion;