import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { WithAuth } from "../../components/with-auth";
import ChatInterface from "../../components/chat-interfact";
import MainWrapper from "../../layout/MainWrapper";
import { createAxiosInstance } from "../../api/ApiRequest";
import type { GetProp, TableProps, InputRef, TableColumnType } from 'antd';
import { Button, Input, Space, Table, Modal, Form, InputNumber, Row, Col, Select } from 'antd';
import type { FilterDropdownProps, SorterResult } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { format } from 'date-fns';
import { SearchOutlined, EditOutlined, SendOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { notifyError, notifySuccess } from "../../utils/toast";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
	_id: String,
	__v: String,
	index: Number,
    chat_id:  Number,
	username: String,
	firstname: String,
	lastname: String,
    wallet:  String,
    withdraw_wallet:  String,
    active_token:  String,
    location:  String,
    referral_chat_id:  Number,
    expire_time: String ,
	referral_username: String,
    createdAt:  Number,
}
type DataIndex = keyof DataType;

interface TableParams {
	pagination?: TablePaginationConfig;
	sortField?: SorterResult<any>['field'];
	sortOrder?: SorterResult<any>['order'];
	filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const getParams = (params: TableParams) => ({
	results: params.pagination?.pageSize,
	page: params.pagination?.current,
	...params,
});

interface Message {
    id: number;
    text: string;
    sender: "user" | "admin";
    timestamp: number;
}

interface UserItem {
	value: string;
	label: string;
}

const Users: React.FC = () => {
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
		
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const [totalUsers, setTotalUsers] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [refreshFlag, setRefreshFlag] = useState(false);
    const [editId, setEditId] = useState(0);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [userMessages, setUserMessages] = useState<Message[]>([]);
	const [referralChatIdModalOpen, setReferralChatIdModalOpen] = useState(false);
    const [userItems, setUserItems] = useState<UserItem[]>([]);
	const searchInput = useRef<InputRef>(null);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	}

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps['confirm'],
		dataIndex: DataIndex,
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

    const onFinish = async (values: any) => {
        let loginRes = await axiosInstance.post(`/api/v1/update_expire_time`, {
            ...values
        });
        if (loginRes.data) {
            let { flag, message } = loginRes.data;
			handleOk();
            if (!flag) {
                notifyError(message);
            } else {
				notifySuccess('Update Expire Time successed.');
				setRefreshFlag(!refreshFlag);
			}
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onSetReferralFinish = async (values: any) => {
        let updateRes = await axiosInstance.post(`/api/v1/update_referral_chat_id`, {
            ...values
        });
        if (updateRes.data) {
            let { flag, message } = updateRes.data;
			setReferralChatIdModalOpen(false)
            if (!flag) {
                notifyError("Network internal Error.");
            } else {
				notifySuccess('Update Referral Chat Id successed.');
				setRefreshFlag(!refreshFlag);
			}
        }
    };


	const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
				ref={searchInput}
				placeholder={`Search ${dataIndex}`}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
				style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button
					onClick={() => clearFilters && handleReset(clearFilters)}
					size="small"
					style={{ width: 90 }}
				>
					Reset
				</Button>
				<Button
					type="link"
					size="small"
					onClick={() => {
					confirm({ closeDropdown: false });
					setSearchText((selectedKeys as string[])[0]);
					setSearchedColumn(dataIndex);
					}}
				>
					Filter
				</Button>
				<Button
					type="link"
					size="small"
					onClick={() => {
					close();
					}}
				>
					close
				</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
		<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		onFilter: (value, record) =>
		record[dataIndex]
			.toString()
			.toLowerCase()
			.includes((value as string).toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});

	const [form] = Form.useForm();

	const expireTimeEdit = (_id: String) => {
		showModal();
		form.setFieldsValue({
			db_id: _id,
		});
	};

	const [referralForm] = Form.useForm();

	const columns: ColumnsType<DataType> = [
		{
			title: 'No',
			dataIndex: 'index',
			sorter: true,
			width: '5%'
		},
		{
			title: 'First Name',
			dataIndex: 'firstname',
			sorter: true,
			...getColumnSearchProps('firstname'),
			width: '10%',
		},
		{
			title: 'Last Name',
			dataIndex: 'lastname',
			sorter: true,
			...getColumnSearchProps('lastname'),
			width: '10%',
		},
		{
			title: 'Wallet Address',
			dataIndex: 'wallet',
			width: '10%',
			sorter: false,
			// ...getColumnSearchProps('wallet'),
		},
		{
			title: 'Withdarw_wallet',
			dataIndex: 'withdraw_wallet',
			width: '15%',
			sorter: true,
			...getColumnSearchProps('withdraw_wallet'),
		},
		{
			title: 'Chat Id',
			dataIndex: 'chat_id',
			width: '8%',
			sorter: true,
			// ...getColumnSearchProps('chat_id'),
		},
		{
			title: 'Expire Time',
			dataIndex: 'expire_time',
			width: '10%',
			sorter: true,
			render: (item) => {
				if (item && (item.split("||")).length > 1) return (
					<>
						{format((item.split("||"))[0], 'yyyy-MM-dd HH:mm')} <a href="#" title="give access" onClick={() => expireTimeEdit((item.split("||"))[1])}><EditOutlined /></a>
					</>
				) ;
				else return (
					<>
						<a href="#" title="give access" onClick={() => expireTimeEdit((item.split("||"))[0])}><EditOutlined /></a>
					</>
				);
			},
			// ...getColumnSearchProps('expire_time'),
		},
		{
			title: 'Telegram Id',
			dataIndex: 'username',
			width: '10%',
			sorter: true,
			...getColumnSearchProps('username'),
		},
		{
			title: 'Referral Chat Id',
			dataIndex: 'referral_chat_id',
			width: '8%',
			sorter: true,
			...getColumnSearchProps('referral_chat_id'),
		},
		{
			title: 'Referral User Id',
			dataIndex: 'referral_username',
			width: '8%',
			sorter: false,
		},
		{
			title: '',
			dataIndex: 'chat_id',
			width: '7%',
			sorter: false,
			render: (item) => (
				<>
					<a href="#" title="Set Referral Chat Id" onClick={()=>getUserList(item)}><UsergroupAddOutlined /></a>&nbsp;&nbsp;&nbsp;
					<a href="#" title="Send message" onClick={()=>getMessages(item)}><SendOutlined /></a>
				</>
			)
			// ...getColumnSearchProps('chat_id'),
		},
	];

    const getMessages = async (id: number) => {
		setEditId(id);
        setLoading(true);
        let results = await axiosInstance.post('/api/v1/get_user_faq', { chatid: id });
        if (results.data && results.data.flag && results.data.flag == true) {
            setUserMessages(results.data.messages);
            setLoading(false);
            setChatModalOpen(true);
        } else {
            notifyError(results.data.message);
            setLoading(false);
        }
    }

    const getUserList = async (id: number) => {
		setEditId(id);
		referralForm.setFieldsValue({
			chat_id: id,
		});
        setLoading(true);
        let results = await axiosInstance.post('/api/v1/get_user_list');
        if (results.data && results.data.flag && results.data.flag == true) {
            setUserItems(results.data.items);
            setLoading(false);
            setReferralChatIdModalOpen(true);
        } else {
            notifyError(results.data.message);
            setLoading(false);
        }
    }
		
	const [data, setData] = useState<DataType[]>();
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState<TableParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});

	const fetchData = async () => {
		setLoading(true);
		let results = await axiosInstance.post('/api/v1/get_users', getParams(tableParams));
		if (results.data && results.data.flag && results.data.flag == true) {
			setData(results.data.users);
			setTotalUsers(results.data.totalNum);
			setLoading(false);
			setTableParams({
				...tableParams,
				pagination: {
					...tableParams.pagination,
					total: results.data.totalNum,
					// 200 is mock data, you should read it from server
					// total: data.totalCount,
				},
			});
		} else {
			notifyError(results.data.message);
			localStorage.removeItem('token');
			history('/login');
		}
	};

	useEffect(() => {
		fetchData();
	}, [
		tableParams.pagination?.current,
		tableParams.pagination?.pageSize,
		tableParams?.sortOrder,
		tableParams?.sortField,
		JSON.stringify(tableParams.filters),
		refreshFlag
	]);

	const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
			sortField: Array.isArray(sorter) ? undefined : sorter.field,
		});

		// `dataSource` is useless since `pageSize` changed
		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setData([]);
		}
	};


    return (
        <WithAuth>
            <MainWrapper>
				<span>Total users: {totalUsers}</span>
				<Table
					columns={columns}
					rowKey={(record) => record._id.toString()}
					dataSource={data}
					pagination={tableParams.pagination}
					loading={loading}
					onChange={handleTableChange}
				/>
				<Modal title="Set Additional Expire Time" open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
					<Form
						form={form}
						name="basic"
						labelCol={{
							span: 8,
						}}
						wrapperCol={{
							span: 16,
						}}
						style={{
							maxWidth: 600,
							margin: 'auto'
						}}
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Form.Item
							label="Additional Times"
							name="additional_time"
							rules={[
								{
									required: true,
									message: 'Please input additional times!',
								},
							]}
						>
							<InputNumber placeholder="Please input additional times." />
						</Form.Item>

						<Form.Item
							label="D _id"
							name="db_id"
							style={{display: "none"}}
						>
							<Input />
						</Form.Item>
                
						<Row>
							<Col span={12}></Col>
							<Col span={6}>
								<Form.Item
									wrapperCol={{
										offset: 16,
										span: 2,
									}}
								>
									<Button type="primary" htmlType="submit">
										Ok
									</Button>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									wrapperCol={{
										offset: 8,
										span: 2,
									}}
								>
									<Button type="default" onClick={handleCancel}>
										Cancel
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
				<Modal title="Set Referral Chat Id" open={referralChatIdModalOpen} footer={null} onOk={() => setReferralChatIdModalOpen(false)} onCancel={() => setReferralChatIdModalOpen(false)}>
					<Form
						form={referralForm}
						name="basic"
						labelCol={{
							span: 8,
						}}
						wrapperCol={{
							span: 16,
						}}
						style={{
							maxWidth: 600,
							margin: 'auto'
						}}
						initialValues={{
							remember: true,
						}}
						onFinish={onSetReferralFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Form.Item
							label="Referral Chat Id"
							name="referral_chat_id"
						>
							<Select
								showSearch
								style={{ width: 200 }}
								placeholder="Search to Select"
								optionFilterProp="label"
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
								}
								options={userItems}
							/>
						</Form.Item>

						<Form.Item
							label="DB _id"
							name="chat_id"
							style={{display: "none"}}
						>
							<Input />
						</Form.Item>
                
						<Row>
							<Col span={12}></Col>
							<Col span={6}>
								<Form.Item
									wrapperCol={{
										offset: 16,
										span: 2,
									}}
								>
									<Button type="primary" htmlType="submit">
										Ok
									</Button>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									wrapperCol={{
										offset: 8,
										span: 2,
									}}
								>
									<Button type="default" onClick={()=>setReferralChatIdModalOpen(false)}>
										Cancel
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
				<Modal
					title="Chat History"
					centered
					open={chatModalOpen}
					onOk={() => setChatModalOpen(false)}
					onCancel={() => setChatModalOpen(false)}
					width={1500}
				>
					<ChatInterface key={JSON.stringify(userMessages)} newMessages={userMessages} userChatId={editId} />
				</Modal>
            </MainWrapper>
        </WithAuth>
    )
}

export default Users;