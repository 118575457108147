import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { createAxiosInstance } from "../../api/ApiRequest";
import type { GetProp, TableProps, InputRef, TableColumnType } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { FilterDropdownProps, SorterResult } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { notifyError } from "../../utils/toast";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
	_id: String,
	__v: String,
	index: Number,
	fullname: String,
	username: String,
	token_address: String,
	token_name: String,
	dex: String,
	pool_id: String,
	pool_type: String,
	wallet: String,
	on_ranking_notification: Boolean,
	ranking_notification_chat_id: Number,
	expire_time: Number,
	createdAt: Number
}
type DataIndex = keyof DataType;

interface TableParams {
	pagination?: TablePaginationConfig;
	sortField?: SorterResult<any>['field'];
	sortOrder?: SorterResult<any>['order'];
	filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const getParams = (params: TableParams) => ({
	results: params.pagination?.pageSize,
	page: params.pagination?.current,
	...params,
});

const Coins: React.FC = () => {
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
		
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const [totalCoins, setTotalCoins] = useState(0);
	const searchInput = useRef<InputRef>(null);

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps['confirm'],
		dataIndex: DataIndex,
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
				ref={searchInput}
				placeholder={`Search ${dataIndex}`}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
				style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button
					onClick={() => clearFilters && handleReset(clearFilters)}
					size="small"
					style={{ width: 90 }}
				>
					Reset
				</Button>
				<Button
					type="link"
					size="small"
					onClick={() => {
					confirm({ closeDropdown: false });
					setSearchText((selectedKeys as string[])[0]);
					setSearchedColumn(dataIndex);
					}}
				>
					Filter
				</Button>
				<Button
					type="link"
					size="small"
					onClick={() => {
					close();
					}}
				>
					close
				</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
		<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		onFilter: (value, record) =>
		record[dataIndex]
			.toString()
			.toLowerCase()
			.includes((value as string).toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});


	const columns: ColumnsType<DataType> = [
		{
			title: 'No',
			dataIndex: 'index',
			sorter: true,
			width: '5%'
		},
		{
			title: 'User Name',
			dataIndex: 'fullname',
			sorter: true,
			// render: (user_name) => `${user_name.firstname} ${user_name.lastname}`,
			...getColumnSearchProps('fullname'),
			width: '15%',
		},
		{
			title: 'Telegram Username',
			dataIndex: 'username',
			width: '10%',
			sorter: true,
			...getColumnSearchProps('username'),
		},
		{
			title: 'Token Address',
			dataIndex: 'token_address',
			width: '15%',
			sorter: true,
			render: (text) => (
					<>
						<a href={`https://dexscreener.com/solana/${text}`} target="_blank" style={{textDecoration: 'none'}}>
							{text}
						</a>
					</>
				),
			...getColumnSearchProps('token_address'),
		},
		{
			title: 'Token Symbol',
			dataIndex: 'token_name',
			width: '10%',
			sorter: true,
			...getColumnSearchProps('token_name'),
		},
		{
			title: 'Dex',
			dataIndex: 'dex',
			width: '5%',
			sorter: true,
			...getColumnSearchProps('dex'),
		},
		// {
		// 	title: 'Pool Id',
		// 	dataIndex: 'pool_id',
		// 	width: '15%',
		// 	sorter: true,
		// },
		// {
		// 	title: 'Pool Type',
		// 	dataIndex: 'pool_type',
		// 	width: '5%',
		// 	sorter: true,
		// },
		{
			title: 'Wallet',
			dataIndex: 'wallet',
			width: '10%',
			sorter: false,
			...getColumnSearchProps('wallet'),
		},
		{
			title: 'Rank Notification',
			dataIndex: 'on_ranking_notification',
			width: '5%',
			sorter: true,
			render: (item) => {
				if (item) return <CheckCircleTwoTone />
				else return <CloseCircleTwoTone />
			},
			// ...getColumnSearchProps('on_ranking_notification'),
		},
		{
			title: 'Rank Notification Telegram Chat Id',
			dataIndex: 'ranking_notification_chat_id',
			width: '15%',
			sorter: true,
			...getColumnSearchProps('ranking_notification_chat_id'),
		},
	];
		
	const [data, setData] = useState<DataType[]>();
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState<TableParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});

	const fetchData = async () => {
		setLoading(true);
		let results = await axiosInstance.post('/api/v1/get_tokens', getParams(tableParams))
		if (results.data && results.data.flag && results.data.flag == true) {
			setData(results.data.tokens);
			setTotalCoins(results.data.totalNum);
			setLoading(false);
			setTableParams({
			...tableParams,
				pagination: {
					...tableParams.pagination,
					total: results.data.totalNum,
					// 200 is mock data, you should read it from server
					// total: data.totalCount,
				},
			});
		} else {
			notifyError(results.data.message);
			localStorage.removeItem('token');
			history('/login');
		}
	};

	useEffect(() => {
		fetchData();
	}, [
		tableParams.pagination?.current,
		tableParams.pagination?.pageSize,
		tableParams?.sortOrder,
		tableParams?.sortField,
		JSON.stringify(tableParams.filters),
	]);

	const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
			sortField: Array.isArray(sorter) ? undefined : sorter.field,
		});

		// `dataSource` is useless since `pageSize` changed
		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setData([]);
		}
	};

    return (
        <WithAuth>
            <MainWrapper>
				<span>Total Coins: {totalCoins}</span>
				<Table
					columns={columns}
					rowKey={(record) => record._id.toString()}
					dataSource={data}
					pagination={tableParams.pagination}
					loading={loading}
					onChange={handleTableChange}
				/>
            </MainWrapper>
        </WithAuth>
    )
}

export default Coins;