import React, { useEffect, useState, useCallback, useRef } from "react";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { Col, Row, Tabs } from 'antd';
import { CodeOutlined, StopOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import { WEBSOCKET_URL, MAIN_PM2_PROCESSES, OTHER_PM2_PROCESSES } from '../../Constants';
import { createAxiosInstance } from "../../api/ApiRequest";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utils/toast";

interface LogMessage {
    process: string;
    type: string;
    message: string;
    timestamp: number;
}

const MAX_LOG_LENGTH = 50;

interface ColorMap {
    [key: string]: string;
}

const BotLog: React.FC = () => {
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
    let [logs1, setLogs1] = useState<LogMessage[]>([]);
    let [logs2, setLogs2] = useState<LogMessage[]>([]);
    let [logs3, setLogs3] = useState<LogMessage[]>([]);
    let [logs4, setLogs4] = useState<LogMessage[]>([]);
    let [logs5, setLogs5] = useState<LogMessage[]>([]);
    let [logs6, setLogs6] = useState<LogMessage[]>([]);
    const logRef1 = useRef<HTMLPreElement>(null);
    const logRef2 = useRef<HTMLPreElement>(null);
    const logRef3 = useRef<HTMLPreElement>(null);
    const logRef4 = useRef<HTMLPreElement>(null);
    const logRef5 = useRef<HTMLPreElement>(null);
    const logRef6 = useRef<HTMLPreElement>(null);

    const appendLog = useCallback((log: LogMessage) => {
        switch (log.type) {
            case 'out1':
                setLogs1(prevLogs => {
                    if (prevLogs.length > MAX_LOG_LENGTH) prevLogs = prevLogs.slice(-MAX_LOG_LENGTH);
                    return [...prevLogs, log]
                });
                break;
            case 'out2':
                setLogs2(prevLogs => {
                    if (prevLogs.length > MAX_LOG_LENGTH) prevLogs = prevLogs.slice(-MAX_LOG_LENGTH);
                    return [...prevLogs, log]
                });
                break;
            case 'out3':
                setLogs3(prevLogs => {
                    if (prevLogs.length > MAX_LOG_LENGTH) prevLogs = prevLogs.slice(-MAX_LOG_LENGTH);
                    return [...prevLogs, log]
                });
                break;
            case 'out4':
                setLogs4(prevLogs => {
                    if (prevLogs.length > MAX_LOG_LENGTH) prevLogs = prevLogs.slice(-MAX_LOG_LENGTH);
                    return [...prevLogs, log]
                });
                break;
            case 'out5':
                setLogs5(prevLogs => {
                    if (prevLogs.length > MAX_LOG_LENGTH) prevLogs = prevLogs.slice(-MAX_LOG_LENGTH);
                    return [...prevLogs, log]
                });
                break;
            case 'out6':
                setLogs6(prevLogs => {
                    if (prevLogs.length > MAX_LOG_LENGTH) prevLogs = prevLogs.slice(-MAX_LOG_LENGTH);
                    return [...prevLogs, log]
                });
                break;
        }
    }, []);

    useEffect(() => {
        const socket = new WebSocket(WEBSOCKET_URL);

        socket.onopen = () => {
            console.log('Connected to server');
        };

        socket.onmessage = (event: MessageEvent) => {
            try {
                const log: LogMessage = JSON.parse(event.data);
                if (log.process && log.process.length > 0) console.log(log);
                else console.log(log);

                appendLog(log);
            } catch (error) {
                console.error('Error parsing message:', error);
                console.error('Received data:', event.data);
            }
        };

        socket.onclose = () => {
            console.log('Disconnected from server');
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => {
            socket.close();
        };
    }, [appendLog]);

    useEffect(() => {
        const refs = [logRef1, logRef2, logRef3, logRef4, logRef5, logRef6];
        refs.forEach(ref => {
            if (ref.current) {
                ref.current.scrollTop = ref.current.scrollHeight;
            }
        });
    }, [logs1, logs2, logs3, logs4, logs5, logs6]);

    
    function parseANSI(text: string): React.ReactNode[] {
        const colorMap: ColorMap = {
            '31': 'red',
            '32': 'green',
            '34': 'blue',
            '36': 'cyan',
            '39': 'white' // default color
        };

        const parts = text.split(/(\x1B\[\d+m|\[[\d:\.]+\])/);
        let currentColor = 'white';
        
        return parts.map((part, index) => {
            // Check for timestamp
            if (part.match(/^\[[\d:\.]+\]$/)) {
                return <span key={index} className="white">{part}</span>;
            }

            // Check for color code
            const colorMatch = part.match(/\x1B\[(\d+)m/);
            if (colorMatch) {
                currentColor = colorMap[colorMatch[1]] || 'white';
                return null;
            }

            // Return colored text
            return <span key={index} className={currentColor}>{part}</span>;
        }).filter(Boolean);
    }

    const renderLogs = (logs: LogMessage[]) => {
        return logs.map((log, index) => {
            const splited_messages = log.message.split('\n');
            if (splited_messages.length > 0) splited_messages.pop();
            return splited_messages.map((message)=>(
                <div key={index}>
                    <span style={{ color: 'green' }}>{log.process}  || </span>
                    {parseANSI(message)}
                </div>
            ));
        });
    };

    const handleRestartProcess = async (processName: string | undefined) => {
        try {
            if (processName == undefined) notifyError(`An error occurs during restart pm2 process ${processName}`);
            const result = await axiosInstance.post('/api/v1/restart_process', { process_name: processName });
            if (result.data && result.data.flag === true) {
                notifySuccess(`Successful to restart pm2 process ${processName}`);
            } else {
                console.log(result.data.message);
                notifyError(`An error occurs during restart pm2 process ${processName}`);
            }
        } catch (error) {
            console.log(error);
            notifyError(`An error occurs during restart pm2 process ${processName}`);
        }
    }

    const handleStopProcess = async (processName: string | undefined) => {
        try {
            if (processName == undefined) notifyError(`An error occurs during stop pm2 process ${processName}`);
            const result = await axiosInstance.post('/api/v1/stop_process', { process_name: processName });
            if (result.data && result.data.flag === true) {
                notifySuccess(`Successful to stop pm2 process ${processName}`);
            } else {
                console.log(result.data.message);
                notifyError(`An error occurs during stop pm2 process ${processName}`);
            }
        } catch (error) {
            console.log(error);
            notifyError(`An error occurs during stop pm2 process ${processName}`);
        }
    }

    const LogPane = ({ logs, logRef, title }: { logs: LogMessage[], logRef: React.RefObject<HTMLPreElement>, title?: string }) => {
        const css_height = title == MAIN_PM2_PROCESSES[0] || title == OTHER_PM2_PROCESSES[0] ? "calc(100vh - 300px)": "calc(50vh - 180px)";
        return (
            <div style={{ display: "grid", margin: "5px" }}>
                <Row className="my-row">
                    <Col span={22}>
                        <h4 style={{ textAlign: 'center' }}>{title}</h4>
                    </Col>
                    <Col span={1}>
                        <a href="#" style={{ fontSize: "20px" }} title="restart" onClick={() => handleRestartProcess(title)}>
                            <CodeOutlined />
                        </a>
                    </Col>
                    <Col span={1}>
                        <a href="#" style={{ fontSize: "20px" }} title="stop" onClick={() => handleStopProcess(title)}>
                            <StopOutlined />
                        </a>
                    </Col>
                </Row>
                <pre
                    className="scrollable-code"
                    ref={logRef}
                    style={{
                        color: "white",
                        height: `${css_height}`,
                        overflowY: "auto",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word"
                    }}
                >
                    {renderLogs(logs)}
                </pre>
            </div>
        )
    };

    const renderMainContent = () => (
        <Row className="my-row">
            <Col span={12}>
                <LogPane logs={logs1} logRef={logRef1} title={MAIN_PM2_PROCESSES[0]} />
            </Col>
            <Col span={12}>
                <LogPane logs={logs2} logRef={logRef2} title={MAIN_PM2_PROCESSES[1]} />
                <LogPane logs={logs3} logRef={logRef3} title={MAIN_PM2_PROCESSES[2]} />
            </Col>
        </Row>
    );

    const renderOtherContent = () => (
        <Row className="my-row">
            <Col span={12}>
                <LogPane logs={logs4} logRef={logRef4} title={OTHER_PM2_PROCESSES[0]} />
            </Col>
            <Col span={12}>
                <LogPane logs={logs5} logRef={logRef5} title={OTHER_PM2_PROCESSES[1]} />
                <LogPane logs={logs6} logRef={logRef6} title={OTHER_PM2_PROCESSES[2]} />
            </Col>
        </Row>
    );

    const items = [
        {
            key: '1',
            label: 'Main',
            icon: <FundProjectionScreenOutlined />,
            children: renderMainContent()
        },
        {
            key: '2',
            label: 'Other',
            icon: <FundProjectionScreenOutlined />,
            children: renderOtherContent()
        }
    ]

    return (
        <WithAuth>
            <MainWrapper>
                <Tabs 
                    defaultActiveKey="1"
                    items={items}
                />
            </MainWrapper>
        </WithAuth>
    );
};

export default BotLog;