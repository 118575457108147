import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface WithAuthProps {
    children: ReactNode;
}

export const WithAuth: React.FC<WithAuthProps> = ({ children }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [navigate, token]);

    return <>{children}</>;
};