import React, { useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import MainWrapper from '../layout/MainWrapper';
import { createAxiosInstance } from '../api/ApiRequest';
import { notifyError } from '../utils/toast';
import { useNavigate } from 'react-router-dom';

type FieldType = {
    email?: string;
    password?: string;
    remember?: string;
};

const Login: React.FC = () => {
    const navigate = useNavigate();
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    useEffect(()=>{
        axiosInstance.post(`/api/v1/validate_token`)
            .then((res) => {
                if (res.data && res.data.flag) {
                    navigate('/dashboard/users');
                }
            })
    }, [])

    const onFinish = async (values: any) => {
        let loginRes = await axiosInstance.post(`/api/v1/login`, {
            ...values
        });
        if (loginRes.data) {
            let { flag, message, token } = loginRes.data;
            console.log(flag, token, message);
            if (!flag) {
                notifyError(message);
            }
            if (flag && token) {
                localStorage.setItem('token', token);
                navigate('/dashboard/users');
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <MainWrapper>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                    margin: 'auto'
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Email"
                    name="email"
                    // type="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>
                
                <Row>
                    <Col span={12}>
                        <Form.Item
                            wrapperCol={{
                                offset: 16,
                                span: 8,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Button type='text' onClick={()=>navigate('/forgot-password')}>Forgot password?</Button>
                    </Col>
                </Row>
                
            </Form>
        </MainWrapper>
    )
}

export default Login;