import { Footer } from "antd/es/layout/layout"
import React from 'react'

interface ContextProviderProps {
	children: React.ReactNode;
}

const AppFooter: React.FC<ContextProviderProps> = () => {
    return (
        <Footer
            style={{
            textAlign: 'center',
            }}
        >
            SolARBA ©{new Date().getFullYear()}
        </Footer>
    )
}

export default AppFooter;