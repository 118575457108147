import React, { useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import MainWrapper from '../layout/MainWrapper';
import { createAxiosInstance } from '../api/ApiRequest';
import { notifyError, notifySuccess } from '../utils/toast';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

type FieldType = {
    email?: string;
    username?: string;
    password?: string;
    telegramUsername?: string;
    telegramUserid?: string;
    role?: Int16Array;
};

const Register: React.FC = () => {
    const navigate = useNavigate();
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const onFinish = async (values: any) => {
        let registerRes = await axiosInstance.post(`/api/v1/register`, {
            ...values
        });
        if (registerRes.data) {
            let { flag, message } = registerRes.data;
            console.log(flag, message);
            if (message) {
                notifyError(message);
            } else {
                notifySuccess('Register success. Please wait until the admin allow your permission');
                navigate('/login');
            }
        }
    };

    const onFinishFailed = (errorInfo: Object) => {
        console.log('Failed:', errorInfo);
        // notifyError(String(errorInfo));
    };

    return (
        <MainWrapper>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                    margin: 'auto'
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Username"
                    name="username"
                    // type="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label="Telegram Username"
                    name="telegramUsername"
                    // type="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your telegram username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label="Telegram User Id"
                    name="telegramUserid"
                    // type="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your telegram user id!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label="Email"
                    name="email"
                    // type="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Confirm Password"
                    name="password2"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The password that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item<FieldType>
                    name="role"
                    label="Role"
                    rules={[{ required: true, message: 'Please select role!' }]}
                >
                    <Select placeholder="select your role">
                        <Option value="0">Super Admin</Option>
                        <Option value="1">Team Member</Option>
                        <Option value="2">Server Manager</Option>
                    </Select>
                </Form.Item>
                
                <Row>
                    <Col span={12}>
                        <Form.Item
                            wrapperCol={{
                                offset: 16,
                                span: 8,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Register
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                
            </Form>
        </MainWrapper>
    )
}

export default Register;