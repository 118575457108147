import React, { ReactNode } from 'react';
import { Breadcrumb, Flex, Layout, Menu, theme } from 'antd';
import AppFooter from './AppFooter';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { LoginOutlined, LogoutOutlined, UserOutlined, BarsOutlined, InteractionOutlined, CodeOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;

interface MainWrapperProps {
    children: ReactNode;
}

const MainWrapper: React.FC<MainWrapperProps> = ({ children }) => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const breadcrumb_items = location.pathname.slice(1).split('/').map((route, key) => (
        {
            title: route
        }
    ));

    const signOut = () => {
        localStorage.removeItem('token');
        navigate('/login');
    }

    const items = [
        {
            key: 1,
            label: "Users",
            icon: <UserOutlined />,
            onClick: () => navigate('/dashboard/users')
        },
        {
            key: 2,
            label: 'Coin List',
            icon: <BarsOutlined />,
            onClick: () => navigate('/dashboard/coins')
        },
        {
            key: 3,
            label: "Active Coin",
            icon: <InteractionOutlined />,
            onClick: () => navigate('/dashboard/active_coin')
        },
        {
            key: 4,
            label: "Bot Log",
            icon: <CodeOutlined />,
            onClick: () => navigate('/dashboard/bot_log')
        },
        {
            key: 5,
            label: "Question",
            icon: <CodeOutlined />,
            onClick: () => navigate('/dashboard/answer_question')
        },
        {
            key: 6,
            label: token ? 'Logout' : 'Login',
            icon: token ? <LogoutOutlined /> : <LoginOutlined />,
            onClick: () => token ? signOut() : navigate('/login')
        }
    ];

    return (
        <>
        <Layout>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    outline: '2px solid pink'
                }}
            >
            <Flex className="demo-logo" style={{marginRight: 20}} vertical align='center'>
                <NavLink to={'/'} style={{ textDecoration: 'none' }}>
                    <span style={{
                        fontSize: 30,
                        color: 'white'
                    }}>SolARBA Volume Bot</span>
                    {/* <img style={{ width: 150 }} src={'/logo.png'} /> */}
                </NavLink>
            </Flex>
            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={['0']}
                items={items}
                style={{
                    flex: 1,
                    minWidth: 0,
                    fontSize: 20,
                    justifyContent: 'flex-end'
                }}
            />
            </Header>
            <Content
            style={{
                padding: '0 2rem',
            }}
            >
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
                items={
                    breadcrumb_items
                }
            />
            <div
                style={{
                    background: colorBgContainer,
                    minHeight: 'calc(100vh - 190px)',
                    padding: 24,
                    borderRadius: borderRadiusLG,
                }}
            >
                {children}
            </div>
            </Content>
            <AppFooter children={undefined} />
        </Layout>
        <ToastContainer />
        </>
    );
};
export default MainWrapper;