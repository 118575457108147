import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAxiosInstance } from "../api/ApiRequest";
import { format } from 'date-fns';
import { FiSend } from "react-icons/fi";

interface Message {
    id: number;
    text: string;
    sender: "user" | "admin";
    timestamp: number;
}

interface ChatInterfaceProps {
    newMessages?: Message[];
	userChatId?: Number; 
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({newMessages = [], userChatId = 0}) => {
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const [error, setError] = useState<string>("");
    const chatEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setMessages(newMessages);
    }, [newMessages]);

    const scrollToBottom = () => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputMessage(e.target.value);
        setError("");
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() === "") {
            setError("Please enter a message");
            return;
        }
        const newMessage: Message = {
            id: messages.length + 1,
            text: inputMessage,
            sender: "admin",
            timestamp: Math.floor(new Date().getTime()),
        };
		await axiosInstance.post('/api/v1/save_admin_message', { userChatId: userChatId, message: newMessage });
        setMessages([...messages, newMessage]);
        setInputMessage("");
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSendMessage();
        }
    };

    return (
        <div className="flex flex-col h-screen bg-gray-100 h-chat">
            <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`flex ${message.sender === "admin" ? "justify-end" : "justify-start"}`}
                    >
                        <div
                            className={`max-w-xs lg:max-w-md xl:max-w-lg ${
                                message.sender === "admin" ? "bg-blue-500 text-white" : "bg-white text-gray-800"
                            } rounded-lg p-3 shadow-md`}
                        >
                            <p>{message.text}</p>
                            <p className="text-xs mt-1 opacity-75">
                                {format(new Date(message.timestamp), "yyyy-MM-dd HH:mm")}
                            </p>
                        </div>
                    </div>
                ))}
                <div ref={chatEndRef} />
            </div>
            <div className="bg-white p-4 border-t border-gray-200">
                <div className="flex space-x-2">
                    <input
                        type="text"
                        value={inputMessage}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Type your message..."
                        className={`flex-1 border ${
                            error ? "border-red-500" : "border-gray-300"
                        } rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        aria-label="Type your message"
                    />
                    <button
                        onClick={handleSendMessage}
                        className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        aria-label="Send message"
                    >
                        <FiSend className="w-5 h-5" />
                    </button>
                </div>
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>
        </div>
    );
};

export default ChatInterface;