import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from 'react';
import Home from "../pages/Login";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Users from "../pages/dashboard/Users";
import Coins from "../pages/dashboard/Coins";
import ActiveCoin from "../pages/dashboard/ActiveCoin";
import BotLog from "../pages/dashboard/BotLog";
import AnswerQuestion from "../pages/dashboard/AnswerQuestion";

interface ContextProviderProps {
	children: React.ReactNode;
}

const AppNavigation: React.FC<ContextProviderProps> = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard/users" element={<Users />} />
                <Route path="/dashboard/coins" element={<Coins />} />
                <Route path="/dashboard/active_coin" element={<ActiveCoin />} />
                <Route path="/dashboard/bot_log" element={<BotLog />} />
                <Route path="/dashboard/answer_question" element={<AnswerQuestion />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppNavigation;