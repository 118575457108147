import React, { createContext, useEffect, useState, ReactNode } from 'react';

// Define the shape of your context
interface AppContextType {
	userInfo: UserInfo;
	setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
}

// Define the shape of your userInfo
interface UserInfo {
	// Add properties that your userInfo object will have
	// For example:
	id?: string;
	name?: string;
	email?: string;
}

// Create the context with an initial value matching the interface
export const AppContext = createContext<AppContextType>({
	userInfo: {},
	setUserInfo: () => {},
});

interface ContextProviderProps {
	children: ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
	const [userInfo, setUserInfo] = useState<UserInfo>({});

	useEffect(() => {
		return () => {
		setUserInfo({});
		};
	}, []);

	return (
		<AppContext.Provider
			value={{
				userInfo,
				setUserInfo,
			}}
		>
		{children}
		</AppContext.Provider>
	);
};